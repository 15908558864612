.pagination-container {
    width: calc(100% - 2rem);
    display: flex;
    align-items: center;
    /* position: absolute;
    bottom: 0; */
    justify-content: center;
    margin-top: 20px;
}


.pagination-number,
.pagination-button{
    background-color: transparent;
    border: none;
    /* margin: 0.25rem 0.25rem; */
    cursor: pointer;
    height: 2.3rem;
    width: 2.3rem;
    border-radius: 2rem;
}

.pagination-number:hover,
.pagination-button:not(.disabled):hover {
    background-color: transparent;
    border: none;
    /* margin: 0.2rem 0.25rem; */
    cursor: pointer;
    height: 2.3rem;
    width: 2.3rem;
    border-radius: 2rem;
}


.pagination-number:hover,
.pagination-button:not(.disabled):hover {
  background: #fff;
}